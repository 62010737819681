import { Stack, Box, Heading, Text } from "pws-design-system/design-system";
import React, { useState } from "react";
import { object, string } from "yup";
import ClientRender from "../../../common/components/client-render";
import { ExistingPasswordField } from "../../../common/components/existing-password-field/";
import { ParamValidated } from "../../../common/components/param-validated";
import { LoadingButton } from "../common";
import Layout from "../common/Layout";
import { Form } from "../common/";
import { formDataToJson } from "../../../../utils";

import _get from "lodash.get";
import axios from "axios";
import queryString from "query-string";
import { navigate } from "gatsby";

const schema = object().shape({
  id: string().required(),
  token: string().required()
});

interface FormLayoutProps {
  formState: FormState;
  children: React.ReactNode;
}

const AuthFormLayoutVariant = () => {
  <Stack></Stack>;
};

const FormLayout = ({ formState, children }: FormLayoutProps) => {
  return <>{children}</>;
};

export default function UpdateEmailPage(): React.ReactElement {
  const [formState, setFormState] = useState("idle");

  function handleSuccess(authToken: string) {
    window.location.assign(
      `${process.env.GATSBY_PROD_URL}${process.env.GATSBY_STATION_LIST_URL}?authToken=${authToken}&toast=emailUpdated`
    );
  }

  const handleSubmit = async (
    event,
    setErrors,
    send,
    action,
    withCredentials,
    successHandler,
    errorHandler
  ) => {
    const target = event.target as HTMLFormElement;
    const formData = new FormData(target);
    const queryParams = queryString.parse(location.search);

    setErrors(null);
    send("submitting");

    try {
      const data = { ...queryParams, ...formDataToJson(formData, false) };

      const response = await axios({
        url: action,
        method: "POST",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json"
        },
        data
      });

      const authToken = _get(response, "data.response.authToken", false);
      if (authToken) {
        handleSuccess(authToken);
      } else {
        throw new Error();
      }
    } catch (e) {
      console.log(e);
      const { status } = e.response;
      if (status === 400) {
        navigate("/login", {
          state: {
            flash:
              "Your request to update your email was expired or invalid. Login and change your email again, or contact support."
          }
        });
      } else if (status === 401) {
        setErrors("Your password was incorrect. Please try again.");
      } else {
        setErrors("An unknown error occured.");
      }
    }
  };

  return (
    <ClientRender>
      <ParamValidated
        schema={schema}
        redirect={"/login"}
        flash={() =>
          "Your request to update your email was expired or invalid. Login and change your email again, or contact support."
        }
      >
        <Layout metaTitle={"Complete Email Update"} metaDescription={""}>
          <Form
            HeadingSlot={
              <>
                <Heading variant="headline">Update Email</Heading>
                <Text variant="caption1">Enter your password to finish changing your email.</Text>
              </>
            }
            action={`${process.env.GATSBY_PWS_API_URL}${process.env.GATSBY_UPDATE_EMAIL_URL}`}
            successHandler={handleSuccess}
            submitHandler={handleSubmit}
          >
            <ExistingPasswordField />
          </Form>
        </Layout>
      </ParamValidated>
    </ClientRender>
  );
}
