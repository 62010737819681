import React from "react";
import UpdateEmailPage from "../react/pages/authentication/update-email/";
import { Providers } from "../react/common/layouts/Layout";

export default function UpdateEmailGatsbyEntry() {
  return (
    <Providers>
      <UpdateEmailPage />
    </Providers>
  );
}
