import queryString from "query-string";
import { navigate } from "gatsby";
import React, { Children } from "react";
import _noop from "lodash.noop";

interface ParamValidatedProps {
  schema: any;
  redirect?: string;
  flash?: (error: Error) => string | void;
  children: React.ReactNode;
}

// Parses params from the query string and renders or does a
// redirect on failure.
const ParamValidated = ({
  schema,
  redirect = null,
  flash = _noop,
  children
}: ParamValidatedProps): React.ReactElement | null => {
  const queryParams = queryString.parse(location.search);

  try {
    schema.validateSync(queryParams);
    return <>{children}</>;
  } catch (error) {
    if (redirect) {
      navigate(redirect, {
        state: {
          flash: flash(error)
        }
      });
    }
    return null;
  }
};

export { ParamValidated };
