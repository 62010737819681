import React, { useState } from "react";
import { FormField, Icon } from "pws-design-system/design-system";

interface ExistingPasswordFieldProps {
  id?: string;
  name?: string;
  label?: string;
  required?: boolean;
  field?: any;
  rest?: any;
}

// Use when the user is entering their existing
// password, not setting a new password
const ExistingPasswordField = ({
  id = "password",
  name = "password",
  label = "Password",
  required = true,
  field = [],
  ...rest
}: ExistingPasswordFieldProps): React.ReactElement | null => {
  const [isSecret, setIsSecret] = useState(true);

  const handleLockIconClick = () => {
    setIsSecret(!isSecret);
  };

  const ToggleSecretIcon = () => {
    return isSecret ? (
      <Icon name="view" color="gray.300" onClick={handleLockIconClick} />
    ) : (
      <Icon name="view-off" color="gray.300" onClick={handleLockIconClick} />
    );
  };

  return (
    <FormField
      id={id}
      name={name}
      label={label}
      required={required}
      field={{
        type: isSecret ? "password" : "text",
        variant: "flushed",
        rightElement: <ToggleSecretIcon />,
        ...field
      }}
      {...rest}
    />
  );
};

export { ExistingPasswordField };
