import React, { useState, useLayoutEffect } from "react";
import { isBrowser } from "../../../../utils";

type ClientRenderProps = {
  children: React.ReactElement;
  // A function which returns a component to compile statically and render
  // before client-side bootstrap has initialized.
  Placeholder?: () => React.ReactElement;
};

/*
 * Conditionally render (or don't render), a component before
 * client-side javascript bootstraps.  This is useful for
 * dynamic elements that need to update based on a data request.
 */
const ClientRender: React.FC<ClientRenderProps> = ({
  children,
  Placeholder = () => <></>
}): React.ReactElement => {
  const [isLayedOut, setIsLayedOut] = useState(false);

  // prevents FOUC
  useLayoutEffect(() => {
    setIsLayedOut(true);
  }, []);

  if (isBrowser() === true && isLayedOut === true) {
    return <>{children}</>;
  }
  return Placeholder();
};

export default ClientRender;
